<template>
  <div class="manage_layer_03">
    <div class="layer_top">
      <h2 class="layer_top_title">입점&amp;제휴문의 관리</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_03">
      <form>
        <div class="mb_30" v-if="showType == 'edit'">
          <base-select
            :options="statuses"
            v-model="selectedStatus"
            placeholder="선택"
          />
        </div>
        <div class="mb_30" v-else>
          <span style="font-weight: bold">진행 상태:</span> {{ confirmStatus }}
        </div>
        <table>
          <colgroup>
            <col style="width: 184px" />
            <col style="width: 244px" />
            <col style="width: 184px" />
            <col style="width: 244px" />
            <col style="width: 184px" />
            <col style="width: 214px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">구분</td>
            <td class="box_style_02">{{ type }}</td>
            <td class="box_style_02 list_title_style">담당자 명</td>
            <td class="box_style_02">{{ name }}</td>
            <td class="box_style_02 list_title_style">연락처</td>
            <td class="box_style_02">{{ tel }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">이메일</td>
            <td class="box_style_02">{{ email }}</td>
            <td class="box_style_02 list_title_style">회사명(기관명)</td>
            <td class="box_style_02">{{ company }}</td>
            <td class="box_style_02 list_title_style">문의 일시</td>
            <td class="box_style_02">{{ registerDate }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">내용</td>
            <td class="box_style_03" colspan="5">
              {{ content }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="edit" v-if="showType == 'edit'"
            >수정</base-button
          >
          <base-button type="primary-lg" @click="$emit('close')" v-else
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from "./core/BaseButton.vue";
import BaseSelect from "./core/BaseSelect.vue";
import Dialog from "@/components/Dialog.vue";
import client from "api-client";
export default {
  components: {
    BaseButton,
    BaseSelect,
  },
  props: {
    showType: String,
    id: Number,
  },
  data() {
    return {
      statuses: [
        { name: "접수", value: "1" },
        { name: "처리중", value: "2" },
        { name: "완료", value: "3" },
      ],
      selectedStatus: "",
      type: "",
      name: "",
      tel: "",
      email: "",
      company: "",
      registerDate: "",
      content: "",
      confirmStatus: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async edit() {
      let params = {
        status: this.selectedStatus,
        name: this.name,
        tel: this.tel,
        email: this.email,
        company: this.company,
        type: this.type ? this.type : "-",
        content: this.content,
      };
      await client.inquiryModify(this.id, params).then(
        () => {
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: "진행상태가 정상적으로 수정되었습니다.",
            },
            {
              width: 400,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
          this.$emit("close", { refresh: true });
        },
        (error) => {
          const { data } = error.response;
          let message = data.message;
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        }
      );
      this.$emit("close");
    },
    async fetch() {
      if (this.id) {
        await client.inquiryFindone(this.id).then(
          (response) => {
            const { data } = response;
            this.selectedStatus = data.status;
            if (data.status == 1) {
              this.confirmStatus = "접수";
            } else if (data.status == 2) {
              this.confirmStatus = "처리중";
            } else {
              this.confirmStatus = "완료";
            }

            this.type = data.type;
            this.name = data.name;
            this.tel = data.tel;
            this.email = data.email;
            this.company = data.company;
            this.registerDate = data.registerDate;
            this.content = data.content;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }
    },
  },
};
</script>
